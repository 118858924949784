import React, { Component } from 'react';
import iconTuboRedondo from '../../images/tubos/icon_tubo_redondo.png';
import iconTuboQuadrado from '../../images/tubos/icon_tubo_quadrado.png';

import './Tubos.scss'

export default class TubosQuadrados extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item left left-one">
                                <p className="title">Tubos quadrados e retangulares</p>
                                <div className="icon-wrapper">
                                    <img src={iconTuboQuadrado} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Tipos de aço: 201, 202, 304/L entre outros.</p>
                                    <p>Norma: ASTM A-554</p>
                                    <p>Acabamentos: Decapado, polido e escovado</p>
                                    <p>Diâmetros: A partir de quadrado de 10,00 MM, com diversas variações.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
